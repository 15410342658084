import React from "react";

export const Wolfberryllc = () => {
  return (
    <svg
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_255_179)">
        <path
          d="M22.2436 0H8.75953C8.0391 0 7.37433 0.377963 7.01257 0.993678L0.269001 12.5063C-0.0896669 13.122 -0.0896669 13.878 0.269001 14.4937L7.00948 26.0063C7.36814 26.622 8.03601 27 8.75643 27H22.2405C22.9609 27 23.6257 26.622 23.9874 26.0063L30.7279 14.4937C31.0866 13.878 31.0866 13.122 30.7279 12.5063L23.9874 0.993678C23.6288 0.377963 22.9609 0 22.2405 0H22.2436Z"
          fill="#C9E265"
        />
        <g clip-path="url(#clip1_255_179)">
          <path
            d="M17.9658 13.9318L23.0697 17.1118V10.6174L17.9658 13.9318Z"
            fill="black"
          />
          <path
            d="M7.93018 10.6174V17.1118L13.0341 13.9318L7.93018 10.6174Z"
            fill="black"
          />
          <path
            d="M22.1235 8.72607H8.87645C8.40429 8.72607 8.02958 9.07299 7.95862 9.51969L15.5 14.4166L23.0414 9.51969C22.9704 9.07299 22.5957 8.72607 22.1235 8.72607Z"
            fill="black"
          />
          <path
            d="M17.0991 14.4951L15.7601 15.3642C15.6807 15.4155 15.5908 15.4407 15.4999 15.4407C15.4091 15.4407 15.3192 15.4155 15.2397 15.3642L13.9008 14.4941L7.96045 18.1974C8.03331 18.6403 8.40612 18.9844 8.87639 18.9844H22.1235C22.5938 18.9844 22.9666 18.6403 23.0394 18.1974L17.0991 14.4951Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_255_179">
          <rect width="31" height="27" fill="white" />
        </clipPath>
        <clipPath id="clip1_255_179">
          <rect
            width="15.1395"
            height="14.9211"
            fill="white"
            transform="translate(7.93018 6.39478)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
