import React from "react";

export const MonitorIcon = () => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_255_333)">
        <path
          d="M53.1878 0H4.31274C1.93103 0 0.000244141 1.93079 0.000244141 4.3125V38.8125C0.000244141 41.1942 1.93103 43.125 4.31274 43.125H23.0003V40.25H4.31274C3.51884 40.25 2.8752 39.6064 2.8752 38.8125V4.3125C2.8752 3.5186 3.51884 2.87496 4.31274 2.87496H53.1878C53.9817 2.87496 54.6253 3.5186 54.6253 4.3125V30.1875H57.5003V4.3125C57.5003 1.93079 55.5695 0 53.1878 0Z"
          fill="#99AC52"
        />
        <path
          d="M20.1251 41.6875H17.2501V50.3125H20.1251V41.6875Z"
          fill="#99AC52"
        />
        <path
          d="M10.0627 34.5C9.26878 34.5 8.62514 33.8564 8.62514 33.0625V30.1875H5.75018V33.0625C5.75018 35.4442 7.68097 37.375 10.0627 37.375H12.9376V34.5H10.0627Z"
          fill="#99AC52"
        />
        <path
          d="M8.62514 24.4375H5.75018V27.3124H8.62514V24.4375Z"
          fill="#99AC52"
        />
        <path
          d="M52.5365 30.4491C49.6072 27.5193 45.6339 25.8734 41.491 25.8735C32.8652 25.8739 25.8728 32.8668 25.8731 41.4926C25.8735 50.1184 32.8664 57.1107 41.4922 57.1104C45.6338 57.1103 49.6056 55.4652 52.5345 52.5369C58.6344 46.4381 58.6353 36.5491 52.5365 30.4491ZM41.4836 54.2368C34.4456 54.2344 28.742 48.5271 28.7444 41.4891C28.7469 34.4511 34.4542 28.7475 41.4922 28.75C44.8749 28.7408 48.1205 30.0868 50.5038 32.4874C52.8916 34.8779 54.2324 38.1189 54.2313 41.4977C54.2289 48.5357 48.5216 54.2393 41.4836 54.2368Z"
          fill="#99AC52"
        />
        <path
          d="M68.579 62.5312L59.5558 53.511C59.2862 53.2414 58.9206 53.0898 58.5395 53.0898C58.1582 53.09 57.7928 53.2414 57.5232 53.511L53.5126 57.5216C52.9514 58.0829 52.9514 58.9928 53.5126 59.5543L62.5358 68.5788C62.8054 68.8485 63.171 69 63.5521 69C63.9339 68.9988 64.2997 68.8457 64.5684 68.5745L68.579 64.5639C69.1402 64.0025 69.1402 63.0925 68.579 62.5312ZM63.552 65.5299L56.5614 58.5379L58.5394 56.5599L65.5299 63.552L63.552 65.5299Z"
          fill="#99AC52"
        />
        <path
          d="M52.5363 50.5025L50.5034 52.5354L55.5166 57.5486L57.5495 55.5157L52.5363 50.5025Z"
          fill="#99AC52"
        />
        <path
          d="M41.6876 31.625V34.5C45.6552 34.5047 48.8703 37.72 48.875 41.6875H51.75C51.7438 36.1328 47.2424 31.6314 41.6876 31.625Z"
          fill="#99AC52"
        />
        <path
          d="M25.8751 48.875H14.3752V51.75H25.8751V48.875Z"
          fill="#99AC52"
        />
        <path
          d="M21.5626 5.75005C19.1809 5.75005 17.2501 7.68083 17.2501 10.0625C17.2501 12.4443 19.1809 14.375 21.5626 14.375C23.9443 14.375 25.8751 12.4443 25.8751 10.0625C25.8751 7.68083 23.9443 5.75005 21.5626 5.75005ZM21.5626 11.5C20.7687 11.5 20.1251 10.8563 20.1251 10.0624C20.1251 9.26851 20.7687 8.62487 21.5626 8.62487C22.3565 8.62487 23.0002 9.26851 23.0002 10.0624C23.0002 10.8565 22.3565 11.5 21.5626 11.5Z"
          fill="#99AC52"
        />
        <path
          d="M31.6252 15.8125C29.2435 15.8125 27.3127 17.7432 27.3127 20.125C27.3127 22.5067 29.2435 24.4375 31.6252 24.4375C34.0069 24.4375 35.9377 22.5067 35.9377 20.125C35.9377 17.7432 34.0069 15.8125 31.6252 15.8125ZM31.6252 21.5625C30.8313 21.5625 30.1876 20.9189 30.1876 20.125C30.1876 19.331 30.8313 18.6874 31.6252 18.6874C32.4191 18.6874 33.0627 19.331 33.0627 20.125C33.0626 20.9189 32.4191 21.5625 31.6252 21.5625Z"
          fill="#99AC52"
        />
        <path
          d="M19.1085 10.484L11.9211 17.6714L13.954 19.7043L21.1414 12.5169L19.1085 10.484Z"
          fill="#99AC52"
        />
        <path
          d="M24.0103 10.4877L21.9774 12.5206L29.1648 19.7079L31.1977 17.6751L24.0103 10.4877Z"
          fill="#99AC52"
        />
        <path
          d="M41.6876 5.75005C39.3059 5.75005 37.3751 7.68083 37.3751 10.0625C37.3751 12.4443 39.3059 14.375 41.6876 14.375C44.0693 14.375 46.0001 12.4443 46.0001 10.0625C46.0002 7.68083 44.0694 5.75005 41.6876 5.75005ZM41.6876 11.5C40.8937 11.5 40.25 10.8563 40.25 10.0624C40.25 9.26851 40.8937 8.62487 41.6876 8.62487C42.4815 8.62487 43.1251 9.26851 43.1251 10.0624C43.1251 10.8565 42.4815 11.5 41.6876 11.5Z"
          fill="#99AC52"
        />
        <path
          d="M39.2327 10.4891L32.0453 17.6764L34.0782 19.7093L41.2656 12.5219L39.2327 10.4891Z"
          fill="#99AC52"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_333">
          <rect width="69" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
