import React from 'react'

const SlashScreenSvg = () => {
  return (
    <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_255_514)">
    <path d="M20.5 0L0 7.45714V24.03C0 42.7586 19.8935 47.85 20.0943 47.9014L20.5 48L20.9057 47.9014C21.1065 47.85 41 42.7586 41 24.03V7.45714L20.5 0ZM35.875 24.0214C35.875 38.0657 20.957 41.8886 20.8032 41.9271L20.5 42L20.1968 41.9271C20.0473 41.8886 5.125 38.07 5.125 24.0214V11.5929L20.5 6L35.875 11.5929V24.0214Z" fill="#C9E265"/>
    <path d="M7.59778 13.3243V24.0258C7.59778 30.0215 10.7283 33.8186 14.0553 36.2272C16.8185 38.2286 19.6074 39.1758 20.5 39.4458C21.3968 39.1758 24.1814 38.2286 26.9447 36.2272C30.2716 33.8186 33.4022 30.0172 33.4022 24.0258V13.3243L20.5 8.63147L7.59778 13.3243ZM15.1016 14.6443L18.4628 16.6843V20.4772L15.1102 18.4372L15.0974 18.4329V14.6443H15.1016ZM11.0657 16.6886L14.4311 14.6315V18.4286L14.414 18.4329L11.07 20.4729L11.0657 16.6886ZM11.2963 21.1329L14.7728 19.02L18.245 21.1329L14.777 23.2543L11.2963 21.1329ZM17.1175 22.6158L19.6501 24.3172L19.018 24.7072L16.4854 23.0015L17.1175 22.6158ZM16.7801 26.8629L20.1455 24.8058V28.5986L20.1284 28.6029L16.7843 30.6429V26.8629H16.7801ZM20.4872 33.42L17.0064 31.2986L20.4829 29.1858L23.9551 31.2986L20.4872 33.42ZM20.8288 28.6029L20.816 28.5986V24.8143V24.81L24.1729 26.85V30.6429L20.8288 28.6029ZM21.9478 24.7029L21.3072 24.3129L23.8483 22.6115L24.4847 22.9972L21.9478 24.7029ZM26.5261 14.6443L29.8873 16.6843V20.4772L26.5347 18.4372L26.5218 18.4329V14.6443H26.5261ZM29.6694 21.1329L26.2015 23.2543L22.7208 21.1329L26.1973 19.02L29.6694 21.1329ZM25.8599 14.6315V18.4286L25.8428 18.4329L22.4987 20.4686V16.6886L25.8599 14.6315ZM21.8282 18.8443H19.1418V18.1629H21.8282V18.8443Z" fill="#C9E265"/>
    </g>
    <defs>
    <clipPath id="clip0_255_514">
    <rect width="41" height="48" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default SlashScreenSvg