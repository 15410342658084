import React from "react";

export const MintIcon = () => {
  return (
    <svg
      width="60"
      height="69"
      viewBox="0 0 60 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.199 39.9647C19.199 40.3745 19.4176 40.7532 19.7726 40.9582L29.3845 46.5076C29.7393 46.7102 30.1736 46.7122 30.5317 46.5076L40.1435 40.9582C40.4986 40.7532 40.7171 40.3745 40.7171 39.9647V28.8658C40.7171 28.4573 40.493 28.072 40.1435 27.8724L30.5317 22.3229C30.1768 22.118 29.7394 22.118 29.3845 22.3229L19.7726 27.8724C19.4156 28.0784 19.199 28.4633 19.199 28.8658V39.9647ZM21.4934 30.8527L28.8109 35.0776V43.5273L21.4934 39.3025V30.8527ZM31.1053 43.5273V35.0776L38.4228 30.8527V39.3024L31.1053 43.5273ZM29.9581 24.6409L37.2756 28.8657L29.9581 33.0906L22.6406 28.8658L29.9581 24.6409Z"
        fill="#99AC52"
      />
      <path
        d="M59.3427 24.3241C59.6977 24.1191 59.9163 23.7404 59.9163 23.3306V17.7811C59.9163 17.3713 59.6977 16.9926 59.3427 16.7877L54.5366 14.013C54.1817 13.8081 53.7443 13.8081 53.3894 14.013L49.1571 16.4566L35.9113 8.80911V3.92186C35.9113 3.51203 35.6927 3.13332 35.3377 2.92841L30.5317 0.153685C30.1768 -0.0512284 29.7395 -0.0512284 29.3846 0.153685L24.5785 2.92841C24.2234 3.13332 24.0049 3.51203 24.0049 3.92186V9.47131C24.0049 9.88114 24.2234 10.2599 24.5785 10.4648L29.3846 13.2395C29.7395 13.4444 30.1768 13.4444 30.5317 13.2395L34.7642 10.7959L48.0101 18.4434V23.3306C48.0101 23.7404 48.2286 24.1191 48.5836 24.3241L52.816 26.7677V42.0626L48.5836 44.5063C48.2286 44.7112 48.0101 45.0899 48.0101 45.4997V50.3868L38.5347 55.8574C37.9861 56.1742 37.7979 56.8758 38.1147 57.4244C38.4332 57.9758 39.1358 58.1598 39.6819 57.8443L49.1572 52.3737L53.3896 54.8173C53.7461 55.0231 54.1833 55.0214 54.5368 54.8173L59.3428 52.0426C59.6979 51.8377 59.9164 51.459 59.9164 51.0492V45.4997C59.9164 45.0899 59.6979 44.7112 59.3428 44.5063L55.1103 42.0626V26.7677L59.3427 24.3241ZM33.6169 8.80896L29.9581 10.9213L26.2992 8.80896V4.58421L29.9581 2.47184L33.617 4.58421L33.6169 8.80896ZM50.3044 18.4434L53.9632 16.331L57.6221 18.4434V22.6682L53.9632 24.7806L50.3044 22.6682V18.4434ZM57.6219 50.3868L53.963 52.4992L50.3043 50.3868V46.1619L53.963 44.0496L57.6219 46.1619V50.3868Z"
        fill="#99AC52"
      />
      <path
        d="M35.3377 58.3653L30.5317 55.5906C30.1768 55.3857 29.7395 55.3857 29.3846 55.5906L25.1521 58.0342L11.9062 50.3867V45.4996C11.9062 45.0898 11.6877 44.7111 11.3326 44.5062L7.10029 42.0625V26.7676L11.3326 24.3239C11.6877 24.119 11.9062 23.7403 11.9062 23.3305V18.4434L20.9197 13.2395C21.4683 12.9228 21.6565 12.2211 21.3397 11.6725C21.0228 11.124 20.3216 10.9357 19.7725 11.2526L10.7591 16.4565L6.52684 14.0127C6.17208 13.808 5.73472 13.8078 5.37967 14.0127L0.573587 16.7875C0.218536 16.9924 0 17.3711 0 17.7809V23.3304C0 23.7402 0.218536 24.1189 0.573587 24.3238L4.80608 26.7674V42.0624L0.573587 44.506C0.218536 44.7109 0 45.0896 0 45.4995V51.0491C0 51.4589 0.218536 51.8376 0.573587 52.0425L5.37967 54.8172C5.73458 55.0222 6.17194 55.0222 6.52684 54.8172L10.7592 52.3736L24.005 60.0211V64.9082C24.005 65.318 24.2236 65.6968 24.5786 65.9017L29.3846 68.6765C29.7395 68.8814 30.1768 68.8814 30.5317 68.6765L35.3378 65.9018C35.6929 65.6969 35.9114 65.3182 35.9114 64.9084V59.3589C35.9113 58.9491 35.6927 58.5702 35.3377 58.3653ZM2.29435 18.4433L5.95326 16.3309L9.61202 18.4433V22.6681L5.95326 24.7805L2.29435 22.6681V18.4433ZM9.61188 50.3867L5.95311 52.4991L2.2942 50.3867V46.1618L5.95311 44.0494L9.61188 46.1618V50.3867ZM33.6169 64.246L29.9581 66.3584L26.2992 64.246V60.0211L29.9581 57.9087L33.617 60.0211L33.6169 64.246Z"
        fill="#99AC52"
      />
      <path
        d="M45.5361 30.3238C45.6964 30.9365 46.3234 31.3033 46.9365 31.143C47.5493 30.9824 47.9162 30.3555 47.7557 29.7426C45.6397 21.6622 38.3211 16.0187 29.9583 16.0187C19.8146 16.0187 11.562 24.2713 11.562 34.4152C11.562 44.5591 19.8144 52.8115 29.9582 52.8115C39.2312 52.8115 47.0799 45.8816 48.2148 36.6917C48.2926 36.0629 47.8457 35.4904 47.2169 35.4126C46.5872 35.3341 46.0154 35.7817 45.9379 36.4105C44.9447 44.4527 38.0748 50.5172 29.9582 50.5172C21.0795 50.5172 13.8562 43.2937 13.8562 34.415C13.8562 25.5363 21.0795 18.313 29.9582 18.313C37.2784 18.313 43.6842 23.252 45.5361 30.3238Z"
        fill="#99AC52"
      />
    </svg>
  );
};
