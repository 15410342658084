import React from 'react'

const LinkedInSvg = () => {
  return (
    <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_255_198)">
<path d="M22.2436 0H8.75953C8.0391 0 7.37433 0.377963 7.01257 0.993678L0.269001 12.5063C-0.0896669 13.122 -0.0896669 13.878 0.269001 14.4937L7.00948 26.0063C7.36814 26.622 8.03601 27 8.75643 27H22.2405C22.9609 27 23.6257 26.622 23.9874 26.0063L30.7279 14.4937C31.0866 13.878 31.0866 13.122 30.7279 12.5063L23.9874 0.993678C23.6288 0.377963 22.9609 0 22.2405 0H22.2436Z" fill="#C9E265"/>
<g clip-path="url(#clip1_255_198)">
<path d="M22.9963 19.9999L23 19.9993V14.498C23 11.8068 22.4207 9.73364 19.2744 9.73364C17.7619 9.73364 16.7469 10.5636 16.3325 11.3505H16.2888V9.98489H13.3057V19.9993H16.4119V15.0405C16.4119 13.7349 16.6594 12.4724 18.2763 12.4724C19.8694 12.4724 19.8932 13.9624 19.8932 15.1243V19.9999H22.9963Z" fill="black"/>
<path d="M8.24756 9.9856H11.3576V20H8.24756V9.9856Z" fill="black"/>
<path d="M9.80125 5C8.80687 5 8 5.80687 8 6.80125C8 7.79562 8.80687 8.61937 9.80125 8.61937C10.7956 8.61937 11.6025 7.79562 11.6025 6.80125C11.6019 5.80687 10.795 5 9.80125 5Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_255_198">
<rect width="31" height="27" fill="white"/>
</clipPath>
<clipPath id="clip1_255_198">
<rect width="15" height="15" fill="white" transform="translate(8 5)"/>
</clipPath>
</defs>
</svg>

  )
}

export default LinkedInSvg